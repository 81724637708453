export const Auth = '/auth'
export const Account = '/Account'
export const Customer = '/Customer'
export const Role = '/Role'
export const Organization = '/Organization'
export const Orders = '/Orders'
export const System = '/system'
export const Utils = '/Utils'
export const Clauses = '/Clauses'
export const Product = '/Product'
export const ProductWiki = '/ProductWiki'
export const Announcement = '/Announcement'
export const Document = '/Document'
export const BusinessShop = '/BusinessShop'
export const Rate = '/Rate'
export const OpenApiSecurity = '/OpenApiSecurity'
export const CarOrders = '/CarOrders'
export const AssessDamageOrder = '/AssessDamageOrder'
export const BankFlow = '/BankFlow'
export const LossRate = '/LossRate'
export const CarPolicyInquiry = '/CarPolicyInquiry'
export const Supplier = '/Supplier'
export const MQMaintain = '/MQMaintain'
export const InvoiceImage = '/InvoiceImage'
export const AutoSms = '/AutoSms'
export const AppPaymentAccount = '/AppPaymentAccount'
export const ASUsers = '/ASUsers'
export const AccessoriesOrders = '/AccessoriesOrders'
export const ChargeScore = '/ChargeScore'