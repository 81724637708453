import { loadPage } from '@/utils'

export default [
  {
    path: 'supplier-shop-table-list',
    name: 'SupplierTableList',
    component: loadPage('supplier/TableList.vue'),
    meta: { title: '商铺列表', subMenuKey: ['Supplier'], menuKey: 'supplierTableList' }
  },
  {
    path: 'supplier-shop-add-and-edit',
    name: 'SupplierAddAndEdit',
    component: loadPage('supplier/AddAndEdit.vue'),
  }
]