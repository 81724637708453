import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from 'axios'
import Store from '@/store'
import licia from 'licia'
import { Account } from '@/api'
import { message } from 'ant-design-vue';
import system from './system'
import customer from './customer'
import product from './product'
import businessShop from './business_shop'
import announcement from './announcement'
import rate from './rate'
import order from './order'
import bankFlow from './bankflow'
import supplier from './supplier'
import invoiceImage from './invoice_image'
import accessoriesSupplier from './accessories_supplier'

const loadPage = page => () => import(`@/page/${page}`)

Vue.use(VueRouter)

const routes = [
  {
    path: '/main',
    name: 'Main',
    component: loadPage('Main.vue'),
    redirect: { name: 'Dashboard' },
    meta: {
      idx: 'main'
    },
    beforeEnter: async (_to, _from, next) => {
      if (localStorage && localStorage.getItem('token')) {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
      }
      if (!licia.isEmpty(Store.privateState.role)) {
        next()
        return
      }
      const res = await Axios.get(`${Account}/Auth`);
      if (res.status && res.data) {
        const { role, userInfo, action, partitionId } = res.data
        if (licia.isEmpty(role)) {
          message.error('请登录系统')
          next({ name: 'Login' })
          return
        }
        Store.privateState.token = localStorage.getItem('token')
        Store.privateState.userInfo = userInfo
        Store.privateState.role = role
        Store.privateState.partitionId = partitionId
        if (action) {
          Store.privateState.action = action
        } else {
          Store.privateState.action = []
        }
        next()
        return
      } else {
        next({ name: 'Login' })
      }
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: loadPage('Dashboard.vue')
      },
      ...system,
      ...customer,
      ...product,
      ...businessShop,
      ...announcement,
      ...rate,
      ...order,
      ...bankFlow,
      ...supplier,
      ...invoiceImage,
      ...accessoriesSupplier
    ]
  },
  {
    path: '/',
    redirect: { name: 'Dashboard' }
  },
  {
    path: '/login',
    name: 'Login',
    component: loadPage('Login.vue'),
    meta: {
      title: '登录'
    }
  },
  
]

const router = new VueRouter({ routes })

export default router
