import { loadPage } from '@/utils'

export default [
  {
    path: 'announcement-car-insurance-rate',
    name: 'RateAnnouncementCarInsuranceRate',
    component: loadPage('announcement/CarInsuranceRate.vue'),
    meta: { title: '车险政策', subMenuKey: ['RateAnnouncement'], menuKey: 'RateAnnouncementCarInsuranceRate' }
  },
  {
    path: 'announcement-vehicle-and-vessel-tax-rate',
    name: 'RateAnnouncementVehicleAndVesselTaxRate',
    component: loadPage('announcement/VehicleAndVesselTaxRate.vue'),
    meta: { title: '车船税政策', subMenuKey: ['RateAnnouncement'], menuKey: 'RateAnnouncementVehicleAndVesselTaxRate' }
  },
  {
    path: 'announcement-environment-protection',
    name: 'RateAnnouncementEnvironmentProtection',
    component: loadPage('announcement/EnvironmentProtection.vue'),
    meta: { title: '环保政策', subMenuKey: ['RateAnnouncement'], menuKey: 'RateAnnouncementEnvironmentProtection' }
  },
  {
    path: 'announcement-finance',
    name: 'RateAnnouncementFinance',
    component: loadPage('announcement/Finance.vue'),
    meta: { title: '财税政策', subMenuKey: ['RateAnnouncement'], menuKey: 'RateAnnouncementFinance' }
  },
  {
    path: 'announcement-new-energy',
    name: 'RateAnnouncementNewEnergy',
    component: loadPage('announcement/NewEnergy.vue'),
    meta: { title: '新能源政策', subMenuKey: ['RateAnnouncement'], menuKey: 'RateAnnouncementNewEnergy' }
  },
  {
    path: 'announcement-vehicle-examination',
    name: 'RateAnnouncementVehicleExamination',
    component: loadPage('announcement/VehicleExamination.vue'),
    meta: { title: '检车政策', subMenuKey: ['RateAnnouncement'], menuKey: 'RateAnnouncementVehicleExamination' }
  },
]
