import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import router from './router'
import Store from '@/store'
import Axios from 'axios'
import BaiduMap from 'vue-baidu-map'
import VueQuillEditor from 'vue-quill-editor'
import Viewer from 'v-viewer'
import VueQr from 'vue-qr'
import Config from './config'
import { Table, TableColumn, Button } from 'element-ui';
import '@/plugins/muse-ui'
import 'ant-design-vue/dist/antd.css';
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import 'viewerjs/dist/viewer.css'

import '@/components'

Vue.config.productionTip = false

Vue.component(Button.name, Button);
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.use(Antd);
Vue.use(VueQuillEditor);
Vue.use(Viewer)
Vue.use(VueQr)
Vue.use(BaiduMap, {
  ak: '90GT8hH4mId6I4HgF26U6WlGTEXjC5Lj'
})

// set Axios
if (Config.IS_DEV) {
  Axios.defaults.baseURL = Config.DEV_URL
  Vue.prototype.$appBaseUrl = Config.DEV_URL
} else {
  Axios.defaults.baseURL = Config.PROD_URL
  Vue.prototype.$appBaseUrl = Config.PROD_URL
}
Axios.interceptors.response.use(response => {
  return response.data;
}, error => {
  switch (error.request.status) {
    case 401:
      localStorage.removeItem('token')
      Vue.prototype.$warning({
        title: '提示',
        content: '您的账号已经登录超时，请重新登录。',
        okText: '重新登录',
        maskClosable: false,
        keyboard: false,
        onOk () {
          router.replace({ name: 'Login' })
        },
      });
      return;
    case 400:
      Vue.prototype.$notification.error({ message: '下载异常', description: '请重试!' })
      break;
    case 404:
      Vue.prototype.$notification.error({ message: '请求异常', description: '请求地址不存在!' })
      break;
    default:
      break;
  }
  return Promise.reject(error);
});

Vue.prototype.$axios = Axios

const app = new Vue({
  data: {
    AppName: '快马驿站',
    ...Store
  },
  router,
  render: h => h(App),
}).$mount('#app')

router.afterEach((to, ) => {
  if (!app.$root.$data.views.some(it => it.id === to.name)) {
    if (to.meta.title && to.meta.menuKey) {
      app.$root.$data.views.push({
        id: to.name,
        name: to.meta.title,
        subMenuKey: to.meta.subMenuKey,
        menuKey: to.meta.menuKey,
        activated: true,
      })
      app.$root.$data.viewsAlive.push(to.name)
      
      if (app.$root.$data.selectedKeys[0] !== to.meta.menuKey && to.meta.menuKey) {
        app.$root.$data.selectedKeys = [to.meta.menuKey]
      }
      if (app.$root.$data.openKeys.length === 0 && to.meta.subMenuKey) {
        for (const key of to.meta.subMenuKey) {
          app.$root.$data.openKeys.push(key)
        }
      }
    } else {
      for (const it of app.$root.$data.views) {
        it.activated = false
      }
    }
  } else {
    for (const it of app.$root.$data.views) {
      if (it.id === to.name) {
        it.activated = true
        continue
      }
      it.activated = false
    }
  }
})