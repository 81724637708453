import { loadPage } from '@/utils'

export default [
  {
    path: 'business-shop-table-list',
    name: 'BusinessShopTableList',
    component: loadPage('businessShop/TableList.vue'),
    meta: { title: '商铺列表', subMenuKey: ['Business'], menuKey: 'BusinessShopTableList' }
  },
  {
    path: 'business-shop-add-and-edit',
    name: 'BusinessShopAddAndEdit',
    component: loadPage('businessShop/AddAndEdit.vue'),
  }
]