import { loadPage } from '@/utils'

export default [
    {
        path: 'accessories-table',
        name: 'AccessoriesTable',
        component: loadPage('accessoriesSupplier/Table.vue'),
        meta: { title: '客户列表', subMenuKey: ['Accessories'], menuKey: 'AccessoriesTable' }
      },
      {
        path: 'accessories-order-table',
        name: 'AccessoriesOrderTable',
        component: loadPage('accessoriesSupplier/OrderTable.vue'),
        meta: { title: '配件订单', subMenuKey: ['Accessories'], menuKey: 'AccessoriesOrderTable' }
      },
      {
        path: 'accessories-order-socre-table',
        name: 'AccessoriesOrderScore',
        component: loadPage('accessoriesSupplier/AccessoriesOrderScore.vue'),
        meta: { title: '配件额度订单', subMenuKey: ['Accessories'], menuKey: 'AccessoriesOrderScore' }
      },
]