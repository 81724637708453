import { loadPage } from '@/utils'

export default [
  {
    path: 'customer-table-list',
    name: 'CustomerTableList',
    component: loadPage('customer/TableList.vue'),
    meta: { title: '修理厂列表', subMenuKey: ['customer'], menuKey: 'CustomerTableList' }
  },
  {
    path: 'customer-asscore-table-list',
    name: 'CustomerTableListByAS',
    component: loadPage('customer/TableListByAS.vue'),
    meta: { title: '修理厂配件额度', subMenuKey: ['customer'], menuKey: 'CustomerTableListByAS' }
  },
  {
    path: 'customer-charge-score-table-list',
    name: 'CustomerTableListByChargeScore',
    component: loadPage('customer/TableListByChargeScore.vue'),
    meta: { title: '修理厂挂帐管理', subMenuKey: ['customer'], menuKey: 'CustomerTableListByChargeScore' }
  },
  {
    path: 'customer-asscore-offline-table-list',
    name: 'CustomerTableListByOfflineAS',
    component: loadPage('customer/TableListByOfflineAS.vue'),
    meta: { title: '修理厂线下配件', subMenuKey: ['customer'], menuKey: 'CustomerTableListByOfflineAS' }
  },
  {
    path: 'customer-staff-customer-count',
    name: 'CustomerStaffCustomerCount',
    component: loadPage('customer/StaffCustomerCount.vue'),
    meta: { title: '客户经理展业统计', subMenuKey: ['customer'], menuKey: 'CustomerStaffCustomerCount' }
  },
  {
    path: 'customer-wallet',
    name: 'CustomerWallet',
    component: loadPage('customer/Wallet.vue'),
    meta: { title: '客户钱包', subMenuKey: ['customer'], menuKey: 'CustomerWallet' }
  },
]
