import { loadPage } from '@/utils'

export default [
  {
    path: 'loss-rate-list',
    name: 'LossRateList',
    component: loadPage('rate/LossRate.vue'),
    meta: { title: '定损政策', subMenuKey: ['Rate'], menuKey: 'LossRateList' }
  },
  // {
  //   path: 'rate-car-list',
  //   name: 'RateCarList',
  //   component: loadPage('rate/CarList.vue'),
  //   meta: { title: '车险政策', subMenuKey: ['Rate'], menuKey: 'RateCarList' }
  // },
  // {
  //   path: 'rate-car-form',
  //   name: 'RateCarForm',
  //   component: loadPage('rate/CarForm.vue'),
  //   meta: { title: '车险政策', subMenuKey: ['Rate'], menuKey: 'RateCarForm' }
  // },
]
