import { loadPage } from '@/utils'

export default [
  {
    path: 'product-wiki',
    name: 'ProductWiki',
    component: loadPage('product/Wiki.vue'),
    meta: { title: '产品Wiki', subMenuKey: ['Product'], menuKey: 'ProductWiki' }
  },
  {
    path: 'product-car-insurance-rate',
    name: 'ProductCarInsuranceRate',
    component: loadPage('product/CarInsuranceRate.vue'),
    meta: { title: '车险政策', subMenuKey: ['Product'], menuKey: 'ProductCarInsuranceRate' }
  },
  {
    path: 'product-vehicle-and-vessel-tax-rate',
    name: 'ProductVehicleAndVesselTaxRate',
    component: loadPage('product/VehicleAndVesselTaxRate.vue'),
    meta: { title: '车险政策', subMenuKey: ['Product'], menuKey: 'ProductVehicleAndVesselTaxRate' }
  },
]
