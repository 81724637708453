import Vue from 'vue'
Vue.prototype.$statusEnum = {
  baseStatus: {
    '-2': {
      color: 'red',
      text: '删除'
    },
    '-1': {
      color: 'orange',
      text: '锁定'
    },
    '1': {
      color: 'green',
      text: '启用'
    },
  },
  rateStatus: {
    '-2': {
      color: 'red',
      text: '删除归档'
    },
    '-1': {
      color: 'orange',
      text: '停用'
    },
    '1': {
      color: 'green',
      text: '启用'
    },
  },
  userStatus: {
    '0': {
      color: 'red',
      text: '锁定'
    },
    '1': {
      color: 'green',
      text: '启用'
    },
  },
  carOrderStatus: {
    '-2': {
      color: 'blue',
      text: '蓝冲'
    },
    '-1': {
      color: 'red',
      text: '红冲'
    },
    '1': {
      color: 'orange',
      text: '已登记待匹配政策'
    },
    '2': {
      color: 'orange',
      text: '等待套单信息'
    },
    '3': {
      color: 'cyan',
      text: '政策已匹配'
    },
    '4': {
      color: 'cyan',
      text: '渠道已核对'
    },
    '5': {
      color: 'purple',
      text: '待财务核对'
    },
    '999': {
      color: 'green',
      text: '完成'
    },
  },
  routeName: {
    ML: '美联',
    NA: '年安',
    HZ: '合众'
  },
  conversionTypeName: [
    "判断类"
  ]
}
