import { loadPage } from '@/utils'

export default [
  {
    path: 'system-users',
    name: 'SystemUsers',
    component: loadPage('system/Users.vue'),
    meta: { title: '系统用户', subMenuKey: ['System'], menuKey: 'SystemUsers' }
  },
  {
    path: 'system-role',
    name: 'SystemRole',
    component: loadPage('system/Role.vue'),
    meta: { title: '系统角色', subMenuKey: ['System'], menuKey: 'SystemRole' }
  },
  {
    path: 'system-organization',
    name: 'SystemOrganization',
    component: loadPage('system/Organization.vue'),
    meta: { title: '组织架构', subMenuKey: ['System'], menuKey: 'SystemOrganization' }
  },
  {
    path: 'system-service-agreement',
    name: 'SystemPrivacyPolicy',
    component: loadPage('system/PrivacyPolicy.vue'),
    meta: { title: '隐私政策', subMenuKey: ['System'], menuKey: 'SystemPrivacyPolicy' }
  },
  {
    path: 'system-user-service-agreement',
    name: 'SystemUserServiceAgreement',
    component: loadPage('system/UserServiceAgreement.vue'),
    meta: { title: '用户服务协议', subMenuKey: ['System'], menuKey: 'SystemUserServiceAgreement' }
  },
  {
    path: 'system-introduction-and-features',
    name: 'SystemIntroductionAndFeatures',
    component: loadPage('system/IntroductionAndFeatures.vue'),
    meta: { title: '系统简介和特色', subMenuKey: ['System'], menuKey: 'SystemIntroductionAndFeatures' }
  },
  {
    path: 'open-api-security',
    name: 'OpenApiSecurity',
    component: loadPage('system/OpenApiSecurity.vue'),
    meta: { title: '开放接口Api安全', subMenuKey: ['OpenApiSecurity'], menuKey: 'OpenApiSecurity' }
  },
  {
    path: 'auto-sms',
    name: 'AutoSms',
    component: loadPage('system/AutoSms.vue'),
    meta: { title: '短信转发记录', subMenuKey: ['AutoSms'], menuKey: 'AutoSms' }
  },
  {
    path: 'app-payment-account',
    name: 'AppPaymentAccount',
    component: loadPage('system/AppPaymentAccount.vue'),
    meta: { title: '付款账户管理', subMenuKey: ['AppPaymentAccount'], menuKey: 'AppPaymentAccount' }
  }
]
