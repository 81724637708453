import { loadPage } from '@/utils'

export default [
  {
    path: 'invoice-image-table-list',
    name: 'InvoiceImageTableList',
    component: loadPage('invoiceImages/TableList.vue'),
    meta: { title: '发票列表', subMenuKey: [], menuKey: 'InvoiceImageTableList' }
  },
]
