import { loadPage } from '@/utils'

export default [
  {
    path: 'bankflow-table-list',
    name: 'BankFlowTableList',
    component: loadPage('bankFlow/TableList.vue'),
    meta: { title: '回执单列表', subMenuKey: ['customer'], menuKey: 'BankFlowTableList' }
  },
]
