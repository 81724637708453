import { loadPage } from '@/utils'

export default [
  {
    path: 'order-car',
    name: 'OrderCar',
    component: loadPage('order/Car.vue'),
    meta: { title: '车险保单', subMenuKey: ['Order'], menuKey: 'OrderCar' }
  },
  {
    path: 'order-assess-damage',
    name: 'AssessDamageOrder',
    component: loadPage('order/AssessDamageOrder.vue'),
    meta: { title: '定损单', subMenuKey: ['Order'], menuKey: 'AssessDamageOrder' }
  },
  {
    path: 'CarPolicyInquiry',
    name: 'CarPolicyInquiry',
    component: loadPage('order/CarPolicyInquiry.vue'),
    meta: { title: '车险保单', subMenuKey: ['Order'], menuKey: 'CarPolicyInquiry' }
  },
  {
    path: 'damage-vs-premium',
    name: 'DamageVsPremium',
    component: loadPage('order/DamageVsPremium.vue'),
    meta: { title: '维修保费比', subMenuKey: ['Order'], menuKey: 'DamageVsPremium' }
  },
  {
    path: 'mq-noack',
    name: 'MQNoAck',
    component: loadPage('order/MQNoAck.vue'),
    meta: { title: '保单推送错误日志', subMenuKey: ['Order'], menuKey: 'MQNoAck' }
  },
]
