<template>
  <a-locale-provider :locale="locale">
    <router-view></router-view>
  </a-locale-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  mounted() {
  },
  data () {
    return {
      locale: zhCN,
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.app-card {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
}

.ant-table-thead>tr>th {
    color: rgba(0,0,0,.85);
    font-weight: 500;
    text-align: left;
    background: #f3f3f3;
    border-bottom: 1px solid #f0f0f0;
    transition: background .3s ease;
}

.ant-advanced-search-form {
  background: #fff;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.ant-table-td-style-selected {
  color: #1890ff !important;
}
.components-form-advanced-search {
  padding-bottom: 20px;
}

.components-form-advanced-search .ant-form {
  max-width: none;
  padding: 24px;
  margin-bottom: 24px;
}

.components-form-advanced-search .search-result-list {
  margin-top: 16px;
  border-radius: 6px;
  background-color: #fff;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>
