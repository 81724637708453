import Vue from 'vue'
Vue.prototype.$postForm = (url, params) => {
  //创建form表单
  const temp_form = document.createElement("form");
  temp_form.action = url;
  //如需打开新窗口，form的target属性要设置为'_blank'
  temp_form.target = "_self";
  temp_form.method = "post";
  temp_form.style.display = "none";
  //添加参数
  for (var item in params) {
      var opt = document.createElement("textarea");
      opt.name = item;
      opt.value = params[item];
      temp_form.appendChild(opt);
  }
  document.body.appendChild(temp_form);
  //提交数据
  temp_form.submit();
}